import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { normalize, darken } from 'polished';
import { StaticQuery, graphql } from 'gatsby';

import color from '../theme';
import Header from './Header.jsx';

const Body = styled.div`
  min-width: 100%;
  min-height: 100vh;

  padding-top: 65px;

  margin: 0;

  display: flex;
  flex-direction: column;

  transition: all 300ms ease-out;
`;

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: "HKGrotesk";
    font-weight: 500;
    src: url("/fonts/HKGrotesk-Regular.woff2") format("woff2");
   }

  @font-face {
    font-family: "HKGrotesk";
    font-weight: 300;
    src: url("/fonts/HKGrotesk-Light.woff2") format("woff2");
   }

  @font-face {
    font-family: "HKGrotesk";
    font-weight: bold;
    src: url("/fonts/HKGrotesk-Bold.woff2") format("woff2");
   }


  body {
    font-family: HKGrotesk, sans-serif;
    font-weight: 300;
    overflow-x: hidden;
  }

  html, body {
    box-sizing: border-box;
    color: #444;
    fill: #444;
    scroll-behavior: smooth;
  }

  a {
    color: ${darken(0.1, color)};
  }

  *[id]:before {
    display: block;
    content: " ";
    margin-top: -65px; // Set the Appropriate Height
    height: 65px; // Set the Appropriate Height
    visibility: hidden;
  }

  ul {
    padding-left: 20px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

const titleQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const MainLayout = ({ lang, children }) => (
  <StaticQuery
    query={titleQuery}
    render={data => (
      <>
        <GlobalStyle />
        <Header lang={lang} siteTitle={data.site.siteMetadata.title} />
        <Body>{children}</Body>
      </>
    )}
  />
);

export default MainLayout;
