import React from 'react';

export default ({ size = '1em' }) => (
  <svg height={size} viewBox="0 0 52 52">
    <g strokeWidth="2" strokeMiterlimit="10">
      <path d="M47 40H5a2 2 0 0 1-2-2V14a2 2 0 0 1 2-2h42a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2z" />
      <path d="M49 12L27.04 29.367a2 2 0 0 1-2.08 0L3 12M3 40l16.452-14.788M32 25.212L48.452 40" />
    </g>
  </svg>
);
