import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

import color from '../theme';
import Email from '../icons/Email.jsx';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 2000;

  padding-left: 20px;
  padding-right: 20px;

  height: 45px;
  min-height: 45px;
  line-height: 45px;

  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: ${color};
  color: white;
  fill: white;

  & > * {
    flex: 0 0 auto;
  }

  @media print {
    display: none;
  }
`;

const LargeHeaderWrapper = styled(HeaderWrapper)`
  height: 65px;
  min-height: 65px;
  line-height: 65px;
`;

const Title = styled.a`
  user-select: none;
  display: flex;
  margin-right: auto;

  text-decoration: none;

  color: inherit;
  fill: inherit;

  font-size: large;

  height: 45px;

  & > * {
    flex: 0 0 auto;
  }

  & > :not(:first-child) {
    margin-left: 20px;
  }
`;

const LargeTitle = styled(Title)`
  height: 65px;
  font-size: larger;
`;

const EmailLink = styled.a`
  fill: transparent;
  stroke: white;

  display: flex;
  align-items: center;
`;

const NavWrapper = styled.div`
  margin-right: 20px;
  display: flex;
`;

export const NavigationElement = styled(Link)`
  flex: 0 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;

  user-select: none;
  white-space: nowrap;

  color: inherit;
  text-decoration: none;
  font-weight: lighter;

  ${props =>
    props.selected
      ? `
  font-weight: bold;
  cursor: default;
  `
      : ''};

  :not(:last-child) {
    padding-right: 20px;
  }
`;

const Header = ({ lang }) => (
  <>
    <LargeHeaderWrapper>
      <LargeTitle href="mailto:steve@quaro.tech">
        <span>QuaroTech</span>
      </LargeTitle>

      <NavWrapper>
        <NavigationElement to="/fr" selected={lang === 'fr'}>
          fr
        </NavigationElement>
        <NavigationElement to="/en" selected={lang === 'en'}>
          en
        </NavigationElement>
        <EmailLink href="mailto:steve@quaro.tech">
          <Email size="1.6em" />
        </EmailLink>
      </NavWrapper>
    </LargeHeaderWrapper>
  </>
);

export default Header;
